import { Atom, Ctrl } from ":mods";
import { ROUTES, SUBMISSION_STATUSES } from "../const";
import { setCourseData } from "../store";
import { getStageListDetails } from "../apis";
import { getStageRoute } from "../methods";

export function SideTutorCourse(props: Ctrl.Nav.model.SideNavJSXItemProps) {
  return (
    <Ctrl.Nav.SideElement
      base={props.base}
      // disabled={!$course_data.value}
      groupClass="flex flex-row whitespace-nowrap"
      immediateLoad
      items={[
        {
          title: "Course Material",
          to: getStageRoute(1),
          icon: {
            idle: "icon-local-nav-side:course-material-idle",
            active: "icon-local-nav-side:course-material-active",
          },
          onClick() {
            setCourseData(undefined);
            getStageListDetails(1).then(({ data }) => {
              // console.log("from side course nav getStageListDetails :: ", stage_id);
              setCourseData(data);
            });
          },
        } as Atom.Route.model.AnchorItem,
        {
          title: "Foundation Submissions",
          to: ROUTES.side.courses.foundation_submissions_list("1", SUBMISSION_STATUSES.pending),
          icon: {
            idle: "icon-local-nav-side:submissions-idle",
            active: "icon-local-nav-side:submissions-active",
          },
          onClick() {},
        } as Atom.Route.model.AnchorItem,
        {
          title: "Shortlisted For Advanced Course",
          to: ROUTES.side.courses.foundation_submissions_list("1", SUBMISSION_STATUSES.shortlisted),
          icon: {
            idle: "icon-local-nav-side:resources-idle",
            active: "icon-local-nav-side:resources-active",
          },
          onClick() {},
        } as Atom.Route.model.AnchorItem,
      ]}
    >
      Foundation Course
    </Ctrl.Nav.SideElement>
  );
}
